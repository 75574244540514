.scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.scrollbar-track {
  right: 4px!important;
}
.no-scroll {
  overflow: hidden;
}
.fill-height > div {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
.fill-height .ant-card > .ant-card-body,
.fill-height .ant-card {
  height: 100%;
}
.text-small {
  font-size: 12px;
  text-transform: capitalize;
}
dd {
  margin-bottom: 8px;
}
dd:last-child {
  margin-bottom: 0;
}
dd ul {
  padding-left: 18px;
}
#initial-loader {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#main-layout {
  min-height: 100vh;
}
#content {
  padding: 24px;
}
.content-header-fixed + #content {
  margin-top: 80px;
}
.content-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
.icon-angle.rotate-top {
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important;
}
.icon-angle.rotate-right {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}
.icon-angle.rotate-bottom {
  -webkit-transform: rotate(-90deg) !important;
          transform: rotate(-90deg) !important;
}
.profile-card .ant-card-meta {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0 26px 0;
}
.profile-card .ant-card-meta-title {
  text-transform: capitalize;
  color: #684bff;
  font-size: 20px;
  line-height: 28px;
  white-space: normal;
}
.profile-card .profile-reference {
  text-transform: uppercase;
  color: #8c8c8c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}
.profile-card .ant-card-meta-avatar {
  padding-right: 32px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.profile-card .ant-avatar-lg {
  width: 72px;
  height: 72px;
}
.ant-form fieldset legend {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.45);
  line-height: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid hsl(0, 0%, 92%);
}

.list .ant-table-tbody > tr > td {
  border: none;
  padding: 0;
}
.list .ant-table-thead > tr > th {
  border: none;
}
.list-item-title-index {
  color: #684bff;
  font-weight: 600;
}
.list-item-title {
  color: #100d66;
  font-weight: 600;
  margin-left: 8px;
}
.list-top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 24px;
  min-height: 34px;
}
.list-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 16px 24px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}
.list-header > .ant-row {
  width: 100%;
}
.list-header + .ant-table-wrapper .ant-table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-header .list-header-extra {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.list-header .list-header-extra .ant-select {
  min-width: 200px;
}
.list-skeleton {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
}
.list-skeleton-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  border-bottom: 1px solid #e8e8e8;
}
.list-skeleton-row .ant-skeleton-content .ant-skeleton-title {
  margin: 0;
}
.list-skeleton-row:nth-child(:even) {
  background-color: #f9f9fb;
}
.skeleton-bone {
  width: 100%;
  height: 16px;
  background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.skeleton-bone + .skeleton-bone {
  margin-left: 32px;
}
.list-title {
  color: #100d66;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.list-title .ant-tag {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin-left: 16px;
  font-weight: 600;
  padding: 0 10px;
  height: 24px;
  line-height: 23px;
}
.list-title .ant-dropdown-trigger .anticon {
  margin-left: 4px;
}
.list-title .ant-dropdown-trigger .anticon.icon-angle {
  width: 5px;
  height: 10px;
}
.list-title .ant-dropdown-trigger .anticon.icon-angle svg {
  width: 5px;
  height: 10px;
}
.list-cell {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  white-space: nowrap;
}
.delivery-man-list-item-title .list-item-title {
  text-transform: capitalize;
}
.list > .ant-table-wrapper .ant-table-title + .ant-table-content {
  overflow: auto;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.disabled-text {
  font-style: italic;
  font-weight: 600;
  color: #bfbfbf;
}
.text-capitalize {
  text-transform: capitalize;
}

.form-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #d9d9d9;
  padding: 16px 32px;
  background: #ffffff;
}
.form-actions button + button {
  margin-left: 16px;
}

#main-menu .ant-menu-item-group-title {
  text-transform: uppercase;
  color: #8f73ff;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px 8px 24px;
}
.ant-layout-sider-collapsed #main-menu .ant-menu-item-group-title {
  opacity: 0;
}
#main-menu .ant-menu-item + .ant-menu-item-group,
#main-menu .ant-menu-item-group + .ant-menu-item-group {
  margin-top: 40px;
}
#main-menu .ant-menu-item {
  margin: 0;
}
#main-menu .ant-menu-item a {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#main-menu .ant-menu-item a:focus {
  text-decoration: none;
}
#main-menu .ant-menu-item.ant-menu-item-active a,
#main-menu .ant-menu-item.ant-menu-item-selected a {
  opacity: 1;
}
#main-menu .ant-menu-item.ant-menu-item-selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #8f73ff;
}
#main-menu .ant-menu-item.ant-menu-item-active {
  background-color: #282934;
}

#user-menu {
  padding: 10px 12px;
  margin: 0 24px 40px;
  border-radius: 4px;
  background-color: #282934;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#user-menu:focus,
#user-menu:hover {
  outline: none;
  background-color: #333442;
}
#user-menu p {
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
#user-menu p > span {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20px;
  max-width: 100%;
  width: 100%;
}
#user-menu p > span span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#user-menu .organization-name {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
#user-menu .icon-angle {
  margin-left: 6px;
  width: 4px;
  height: 8px;
}
#user-menu .icon-angle svg {
  width: 4px;
  height: 8px;
  fill: #d4c4ff;
}
#user-menu .user-name {
  color: #d4c4ff;
  font-weight: 400;
}
#user-menu .anticon-compass {
  display: none;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#user-menu .anticon-compass svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}
.ant-layout-sider-collapsed #user-menu {
  margin: 0 10px 40px;
  padding: 20px 0;
}
.ant-layout-sider-collapsed #user-menu .organization-name,
.ant-layout-sider-collapsed #user-menu .user-name {
  display: none;
}
.ant-layout-sider-collapsed #user-menu .anticon-compass {
  display: block;
  opacity: 0.5;
}
.ant-layout-sider-collapsed #user-menu:focus .anticon-compass,
.ant-layout-sider-collapsed #user-menu:hover .anticon-compass {
  opacity: 1;
}

#logo-wrapper {
  margin: 56px 0 40px;
}
#logo-wrapper a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#logo-wrapper .logo-badge {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 60px;
  height: 60px;
  background-color: #684bff;
  border-radius: 10px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#logo-wrapper .icon-logo svg {
  width: 40px;
  height: 45px;
  fill: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#logo-wrapper .icon-logo {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#logo-wrapper .icon-logo-text {
  margin-top: 8px;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout-sider-collapsed #logo-wrapper .logo-badge {
  background-color: transparent;
}
.ant-layout-sider-collapsed #logo-wrapper .icon-logo svg {
  fill: #f3edff;
  width: 48px;
  height: 54px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s;
}
.ant-layout-sider-collapsed #logo-wrapper .icon-logo-text {
  opacity: 0;
}
.ant-layout-sider-children {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 32px;
}
.ant-layout-sider-children #copyright {
  position: absolute;
  color: #43424d;
  bottom: 8px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout-sider-children #copyright a {
  color: #43424d;
}
.ant-layout-sider-children #copyright a:focus,
.ant-layout-sider-children #copyright a:hover {
  color: #684bff;
}
.ant-layout-sider-collapsed .ant-layout-sider-children #copyright {
  opacity: 0;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-layout-sider-trigger i {
  opacity: 0.5;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout-sider-trigger svg {
  fill: #fff;
}
.ant-layout-sider-trigger:focus i,
.ant-layout-sider-trigger:hover i {
  opacity: 1;
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}
.ant-layout-sider-collapsed .ant-layout-sider-trigger i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-layout-sider-collapsed .ant-layout-sider-trigger:focus i,
.ant-layout-sider-collapsed .ant-layout-sider-trigger:hover i {
  -webkit-transform: translateX(4px) rotate(180deg);
          transform: translateX(4px) rotate(180deg);
}
#menu-wrapper {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-bottom: 40px;
}

.empty-result {
  width: 100%;
  text-align: 'center';
}
.empty-result .anticon {
  font-size: 36px;
  opacity: 0.2;
  margin: 16px;
}

#login-layout .login-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  padding: 32px 24px;
  background-image: url(/static/media/login-background.f780f54b.jpg);
  background-size: 1657px 1243px;
  background-position: center center;
}
@media (min--moz-device-pixel-ratio: 2.25), (-webkit-min-device-pixel-ratio: 2.25), (min-device-pixel-ratio: 2.25), (min-resolution: 2.25dppx) {
  #login-layout .login-left {
    background-image: url(/static/media/login-background@2x.3e33a42b.jpg);
  }
}
@media (min--moz-device-pixel-ratio: 2.25), (-webkit-min-device-pixel-ratio: 2.25), (min-device-pixel-ratio: 2.25), (min-resolution: 2.25dppx) {
  #login-layout .login-left {
    background-image: url(/static/media/login-background@3x.a11ee719.jpg);
  }
}
#login-layout .login-left .icon-ezeeworld {
  display: none;
}
#login-layout #logo-wrapper {
  margin: 0;
  justify-self: center;
}
#login-layout #logo-wrapper > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}
#login-layout .logo-badge {
  width: 80px;
  height: 80px;
}
#login-layout .icon-logo svg {
  width: 53px;
  height: 60px;
}
#login-layout .icon-logo-text {
  margin: 0;
}
#login-layout .icon-logo-text svg {
  width: 50vw;
  height: 16vw;
}
#login-layout .icon-email svg {
  width: 14px;
  height: 12px;
}
#login-layout .icon-lock svg {
  width: 14px;
  height: 17px;
}
#login-layout .login-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 32px 24px;
}
#login-layout #link-ezeeworld {
  margin-top: 24px;
  text-align: center;
}
#login-layout #link-ezeeworld svg {
  fill: #d9d9d9;
}
#login-layout .login-form {
  max-width: 100%;
  width: 362px;
}
#login-layout .login-form .ant-row:last-child {
  margin: 0;
}
#login-layout .login-form h1 {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 34px;
  text-transform: uppercase;
  text-align: center;
  color: #684bff;
  margin-bottom: 16px;
}
#login-layout .login-form h1 + p {
  color: #202129;
  font-size: 16px;
  text-align: center;
  margin-bottom: 56px;
}
#login-layout .login-form button {
  margin-top: 29px;
}
#login-layout .login-form .login-remember .ant-form-item-children {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#login-layout .ant-layout-footer {
  color: #d9d9d9;
  margin-top: 32px;
  padding: 0;
  background-color: transparent;
}
#login-layout .login-error {
  position: absolute;
  bottom: -44px;
  line-height: 24px;
}
@media (min-width: 768px) {
  #login-layout {
    height: 100vh;
  }
  #login-layout .login-left {
    -webkit-align-items: center;
            align-items: center;
  }
  #login-layout .login-left .icon-ezeeworld {
    display: block;
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  #login-layout .login-left .icon-ezeeworld svg {
    fill: #fff;
  }
  #login-layout > div {
    height: 100%;
  }
  #login-layout #logo-wrapper > div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
  #login-layout .logo-badge {
    width: 100px;
    height: 100px;
  }
  #login-layout .icon-logo svg {
    width: 68px;
    height: 74px;
  }
  #login-layout .icon-logo-text {
    margin-top: 16px;
  }
  #login-layout .icon-logo-text svg {
    width: 200px;
    height: 53px;
  }
  #login-layout .login-right {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;
  }
  #login-layout #link-ezeeworld {
    display: none;
  }
}

#dashboard-filters .ant-btn-link {
  font-size: 14px;
  padding: 0;
  text-transform: none;
  color: rgba(0, 0, 0, 0.65);
}
#dashboard-filters .ant-btn-link.selected {
  font-weight: 700;
  color: #684bff;
}
#task-statuses-wrapper .metric {
  height: auto;
  margin-bottom: 24px;
}
#task-statuses {
  display: -webkit-flex;
  display: flex;
}
.task-statuses-col {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
}
.task-statuses-row {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  -webkit-align-items: baseline;
          align-items: baseline;
}
.task-statuses-row + .task-statuses-row {
  margin-top: 5px;
}
.task-status {
  font-weight: 600;
  font-size: 14px;
  color: #595959;
}
.task-statuses-divider {
  padding: 24px 32px;
}
[class*=task-statuses-value-] {
  font-size: 24px;
  line-height: 24px;
}
.task-statuses-value-default {
  color: #684bff;
}
.task-statuses-value-danger {
  color: #f5222d;
}
.task-statuses-value-success {
  color: #389e0d;
}
.task-statuses-value-warning {
  color: #ffc069;
}

#content-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transition: box-shadow 0.2s ease-out;
  transition: box-shadow 0.2s ease-out;
}
#content-header.content-header-fixed {
  position: fixed;
  z-index: 1;
  width: 83.2223%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}
#content-header-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  line-height: 1.5;
  -webkit-align-items: center;
          align-items: center;
}
#content-header-nav .content-header-nav-item a {
  display: block;
  padding: 4px 24px 5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  -webkit-transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#content-header-nav .content-header-nav-item a:focus,
#content-header-nav .content-header-nav-item a:hover {
  text-decoration: none;
  color: rgba(104, 75, 255, 0.5);
}
#content-header-nav .content-header-nav-item:empty {
  display: none;
}
#content-header-nav .content-header-nav-item + .content-header-nav-item {
  margin-left: 16px;
}
#content-header-nav .content-header-nav-item.active a {
  color: #fff;
  background-color: #684bff;
}
#content-header-nav .content-header-nav-item.active a:focus,
#content-header-nav .content-header-nav-item.active a:hover {
  color: #fff;
}

.metric {
  text-align: center;
}
.metric .metric-label {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 12px;
  max-height: 16px;
  text-transform: uppercase;
}
.metric .metric-value {
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  color: #684bff;
  margin: 0;
}
.metric.metric-disabled .metric-value {
  color: #e8e8e8;
}
.metric .metric-suffix {
  font-size: 32px;
}
.metric-card .metric {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.metric-card .metric .ant-spin-nested-loading {
  height: 100%;
}
.metric-card .metric .ant-spin-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.metric-card .metric .metric-label {
  margin: 0;
}
.metric-card .metric .metric-value {
  margin: auto 0;
  font-size: 48px;
}
.metric-card {
  min-height: 208px;
}
.metric-card > .ant-card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.metric-card .card-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #8f73ff;
  font-size: 16px;
  font-weight: 600;
}
.metric-card .metric-card-alt {
  margin: 0;
  font-size: 14px;
  text-transform: lowercase;
}
.metric-card .metric-card-alt-value {
  font-size: 16px;
}

#quality-content-wrapper {
  display: -webkit-flex;
  display: flex;
  min-height: 350px;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  #quality-content-wrapper {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}
#quality-content-wrapper > :first-child {
  display: none;
  min-height: 200px;
}
@media (min-width: 768px) {
  #quality-content-wrapper > :first-child {
    display: block;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}
#quality-content-wrapper > :first-child .ant-spin-nested-loading {
  min-height: 350px;
}
#quality-content-wrapper > :first-child .multi-series-chart,
#quality-content-wrapper > :first-child .ant-spin-container {
  height: 100%;
  min-height: 200px;
}
#quality-content-wrapper > :last-child {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
#quality-content-wrapper > :last-child .metric + .metric {
  margin-top: 24px;
}
@media (min-width: 768px) {
  #quality-content-wrapper > :last-child {
    margin: 0 0 32px 32px;
  }
  #quality-content-wrapper > :last-child .metric + .metric {
    margin-top: 0;
  }
}

.delivery-tour-list-progress {
  width: 100%;
}
.delivery-tour-list-progress .progress-infos {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #8f73ff;
  margin-bottom: 8px;
}
.delivery-tour-list-progress .progress-track {
  width: 100%;
  background-color: #e8e8e8;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
}
.delivery-tour-list-progress .progress-task {
  height: 8px;
  width: 100%;
  background-color: #d9d9d9;
}
.delivery-tour-list-progress .progress-task + .progress-task {
  margin-left: 2px;
}

.send-message-popover .ant-popover-inner-content {
  padding: 16px;
}
.send-message-popover .ant-form-item {
  margin-bottom: 16px;
}
.send-message-popover-recipient {
  border-bottom: 1px solid #d9d9d9;
}

#delivery-tour-details {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 0;
  max-height: calc(100vh - 80px);
}
#delivery-tour-details-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#delivery-tour-details-header > * + * {
  margin-left: 16px;
}
.delivery-tour-date {
  color: #100d66;
  font-weight: 500;
}
.delivery-tour-delivery-man {
  background-color: #100d66;
  color: #f3edff;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 16px;
  line-height: 16px;
  margin-left: 16px;
  text-transform: capitalize;
}
#delivery-tour-overview {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 0 0 88px;
          flex: 0 0 88px;
  width: 100%;
  background-color: #31323F;
}
#delivery-tour-overview #overview-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 47px 24px 6px;
}
#delivery-tour-overview .overview-item-track {
  position: relative;
  height: 14px;
}
#delivery-tour-overview .overview-item-track:first-child .overview-tick {
  bottom: 2px;
  height: 12px;
}
#delivery-tour-overview .overview-track {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #3e4051;
  border-radius: 1px;
}
#delivery-tour-overview .overview-bar {
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 0;
  background-color: #8f73ff;
}
#delivery-tour-overview .overview-tick-last,
#delivery-tour-overview .overview-tick {
  position: absolute;
  bottom: 4px;
  height: 8px;
  width: 2px;
  left: 0;
  background-color: #54576e;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
#delivery-tour-overview .overview-tick-last.active,
#delivery-tour-overview .overview-tick.active {
  height: 10px;
  bottom: 2px;
  background-color: #8f73ff;
}
#delivery-tour-overview .overview-tick-last {
  left: auto;
  right: 0;
  bottom: 2px;
  height: 12px;
}
#delivery-tour-overview .icon-agency-marker {
  position: absolute;
  top: -32px;
  left: -8px;
}
#delivery-tour-overview .icon-end-marker {
  position: absolute;
  top: -32px;
  right: -16px;
}
#delivery-tour-overview #overview-times {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 24px;
  color: #d4c4ff;
  font-weight: 600;
  font-size: 12px;
}
#delivery-tour-overview #overview-duration {
  position: absolute;
  top: 12px;
  left: 50%;
  background-color: #44486a;
  padding: 5px 8px 4px;
  border-radius: 4px;
  color: #8f73ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#delivery-tour-timeline-collapse-button {
  position: absolute;
  left: 0;
  z-index: 3;
  top: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#delivery-tour-timeline-collapse-button .anticon {
  position: relative;
  top: -1px;
}
#delivery-tour-timeline-collapse-button svg {
  fill: #fff;
}
.collapsed #delivery-tour-timeline-collapse-button {
  top: 0;
  left: -32px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#delivery-tour-timeline {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #282934;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#delivery-tour-timeline.collapsed {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
#delivery-tour-timeline #timeline-wrapper {
  position: relative;
  min-width: 100%;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
#delivery-tour-timeline .timeline-section-title {
  margin-left: 70px;
  text-align: center;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  #delivery-tour-timeline .timeline-section-title {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 0;
  }
}
#delivery-tour-timeline .timeline-section-title h2 {
  display: inline-block;
  padding: 16px;
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.17px;
  color: #50557c;
  text-transform: uppercase;
  background-color: #282934;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
#delivery-tour-timeline .timeline-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 16px 0;
}
#delivery-tour-timeline .timeline-item h3 {
  font-size: 16px;
}
#delivery-tour-timeline .timeline-item.flash {
  -webkit-animation-name: flash;
          animation-name: flash;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@-webkit-keyframes flash {
  5% {
    background-color: #31323f;
  }
  50% {
    background-color: #31323f;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes flash {
  5% {
    background-color: #31323f;
  }
  50% {
    background-color: #31323f;
  }
  100% {
    background-color: transparent;
  }
}
#delivery-tour-timeline .timeline-item:last-child.timeline-item-agency {
  min-height: 70px;
}
#delivery-tour-timeline .timeline-item:last-child.timeline-item-agency .timeline-track {
  top: 32px;
  bottom: -120px;
}
#delivery-tour-timeline .timeline-item:last-child.timeline-item-agency .timeline-bar {
  top: 32px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
#delivery-tour-timeline .timeline-item:last-child {
  margin-bottom: 32px;
}
#delivery-tour-timeline .timeline-item:last-child .timeline-track {
  bottom: 0;
}
#delivery-tour-timeline .timeline-item:last-child .timeline-bar {
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
#delivery-tour-timeline .timeline-item + .timeline-item {
  position: relative;
  margin-top: 64px;
}
#delivery-tour-timeline .timeline-item + .timeline-item::before {
  content: '. . . . . . . . . . . .';
  color: #434456;
  position: absolute;
  top: -60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 24px;
}
#delivery-tour-timeline .timeline-item-time-icon {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 82px;
          flex: 0 0 82px;
}
#delivery-tour-timeline .timeline-item-time-icon .timeline-track {
  position: absolute;
  top: 4px;
  left: 56px;
  width: 2px;
  bottom: -102px;
  background-color: #3e4051;
  border-radius: 1px;
}
#delivery-tour-timeline .timeline-item-time-icon .timeline-bar {
  position: absolute;
  left: 56px;
  top: 4px;
  width: 2px;
  background-color: #8f73ff;
}
#delivery-tour-timeline .timeline-item-time-icon .icon-map-delivery {
  position: absolute;
  right: 15px;
  top: 0;
}
#delivery-tour-timeline .timeline-item-time-icon .icon-agency-marker {
  position: absolute;
  top: 0;
  right: 16px;
  height: 24px;
  margin-top: 2px;
}
#delivery-tour-timeline .timeline-item-time {
  font-size: 12px;
  font-weight: 600;
  color: #d4c4ff;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  margin: 2px 0 0 10px;
}
#delivery-tour-timeline .timeline-item-content {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-right: 24px;
}
#delivery-tour-timeline .timeline-item-content p {
  margin-bottom: 12px;
}
#delivery-tour-timeline .delivery-recipient-address {
  color: #f5f0ff;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  opacity: 0.5;
}
#delivery-tour-timeline .timeline-item-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 8px;
}
#delivery-tour-timeline .timeline-item-header h5 {
  font-size: 16px;
  color: #d4c4ff;
  margin: 0;
  text-transform: capitalize;
}
#delivery-tour-timeline .timeline-item-type small {
  font-size: 12px;
  color: #b39cff;
  text-transform: uppercase;
}
#delivery-tour-timeline .timeline-item-footer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#delivery-tour-timeline .timeline-item-footer > .ant-btn-group {
  -webkit-transition: top 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: top 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#delivery-tour-timeline .timeline-item-footer > .ant-btn-group:first-child:last-child {
  margin-left: auto;
  position: static;
}
#delivery-tour-timeline .timeline-item-footer > .ant-collapse + .ant-btn-group {
  position: absolute;
  top: -4px;
  right: 0;
}
#delivery-tour-timeline .timeline-item-footer > .ant-collapse.open + .ant-btn-group {
  top: 8px;
}
#delivery-tour-timeline .delivery-details-button,
#delivery-tour-timeline .delivery-message-button {
  padding: 0 20px;
  color: #d4c4ff;
  background-color: #44486a;
  border-color: #44486a;
  -webkit-transition: top 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), right 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: top 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), right 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#delivery-tour-timeline .delivery-details-button:focus,
#delivery-tour-timeline .delivery-message-button:focus,
#delivery-tour-timeline .delivery-details-button:hover,
#delivery-tour-timeline .delivery-message-button:hover {
  background-color: #4e537a;
}
#delivery-tour-timeline .delivery-details-button {
  font-size: 12px;
  line-height: 33px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-left-color: #4e537a;
}
#delivery-tour-timeline .icon-agency-marker svg {
  fill: #b39cff;
}
#delivery-tour-timeline .timeline-tag {
  position: relative;
  margin-top: 16px;
}
#delivery-tour-timeline .timeline-tag-time-icon {
  position: absolute;
  z-index: 1;
  left: -82px;
  width: 63px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#delivery-tour-timeline .timeline-tag-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #f3edff;
}
#delivery-tour-timeline .timeline-tag-content .img-wrapper {
  margin-top: 8px;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  background-color: #424352;
}
#delivery-tour-timeline .timeline-tag-content .img-wrapper.img-error {
  background-color: #424352;
}
#delivery-tour-timeline .timeline-tag-content .img-wrapper .anticon {
  color: #282934;
  font-size: 40px;
}
#delivery-tour-timeline .tag-value {
  color: #d4c4ff;
  font-weight: 600;
  padding: 0 16px;
  margin: 8px 0 0 0;
}
#delivery-tour-timeline .ant-collapse {
  -webkit-transition: margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#delivery-tour-timeline .ant-collapse.open {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-right: -24px;
  margin-left: -82px;
  padding-left: 82px;
  background-color: #31323f;
  padding: 16px 24px 16px 82px;
}
#delivery-tour-timeline .ant-collapse.open + .delivery-details-button {
  position: absolute;
  right: 0;
  top: 16px;
}
#delivery-tour-timeline .ant-collapse-header {
  padding: 0;
}
#delivery-tour-timeline .ant-collapse-content {
  overflow: visible;
}
@media (min-width: 768px) {
  #delivery-tour-timeline {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    bottom: auto;
    -webkit-flex: 0 0 40%;
            flex: 0 0 40%;
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
  }
  #delivery-tour-timeline-collapse-button {
    display: none;
  }
}
.delivery-details-drawer .ant-card:not(:last-child) {
  margin-bottom: 24px;
}
.delivery-details-drawer .ant-card-body p:last-child {
  margin: 0;
}
.delivery-details-drawer img {
  max-width: 100%;
}
#parcel-item-list .ant-list-item-meta-title {
  margin-bottom: 16px;
}
#parcel-item-list .ant-alert {
  margin-top: 16px;
}
.parcel-item-list-item {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-right: 16px;
}
.no-tasks {
  margin-left: 90px;
}
.event-button {
  color: #f3edff;
  font-size: 12px;
  text-transform: uppercase;
}
.event-button::after {
  display: none;
}

#temperature-chart {
  display: -webkit-flex;
  display: flex;
  min-height: 350px;
}
#temperature-chart > :first-child {
  min-height: 200px;
}
@media (min-width: 768px) {
  #temperature-chart > :first-child {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}
#temperature-chart > :first-child .ant-spin-nested-loading {
  min-height: 350px;
}
#temperature-chart > :first-child .multi-series-chart,
#temperature-chart > :first-child .ant-spin-container {
  height: 100%;
  min-height: 200px;
}
.temperature-frozen line {
  stroke: #b37feb;
}
.temperature-fresh line {
  stroke: #5cdbd3;
}

.map {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.map .map-navigation-control {
  position: absolute;
  top: 16px;
  left: 16px;
}
.map .map-popup {
  padding: 14px 14px 11px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: auto;
}
.map .map-popup p:last-child {
  margin: 0;
}
#deckgl-overlay.pointer {
  cursor: pointer !important;
}

.ant-tag[class*=dark] {
  border-width: 0;
}
.ant-tag.dark,
.ant-tag.dark-primary {
  color: #d4c4ff;
  background-color: #44486a;
  border-color: #585d89;
}
.ant-tag.dark-green {
  color: #f6ffed;
  background-color: #7cb305;
  border-color: #5b8c00;
}
.ant-tag.dark-orange {
  color: #feffe6;
  background-color: #d4b106;
  border-color: #ad8b00;
}
.ant-tag.dark-red {
  color: #fff1f0;
  background-color: #cf1322;
  border-color: #a8071a;
}

.img-wrapper {
  position: relative;
  background-color: #f2f2f2;
  max-width: 100%;
  max-height: 100%;
}
.img-wrapper.img-loading {
  background: -webkit-linear-gradient(left, #31323F 25%, #282934 37%, #31323F 63%);
  background: linear-gradient(90deg, #31323F 25%, #282934 37%, #31323F 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.img-wrapper.img-error {
  background: #f2f2f2;
}
.img-wrapper .anticon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 72px;
  color: #bfbfbf;
}
.img-wrapper.img-fill {
  position: relative;
  width: 100%;
  height: 100%;
}
.img-wrapper.img-fill img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-wrapper.img-contain {
  position: relative;
  width: 100%;
  height: 100%;
}
.img-wrapper.img-contain img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.img-wrapper.img-loaded img {
  opacity: 1;
}
.img-wrapper img {
  opacity: 0;
  -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: opacity;
  max-width: 100%;
  max-height: 100%;
}

.delivery-price {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 24px;
  line-height: 32px;
  color: #684bff;
}
.delivery-price i {
  margin-left: 8px;
  font-size: 20px;
}


.customer-profile-infos {
  display: -webkit-flex;
  display: flex;
}
.customer-profile-infos ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 16px;
}
.customer-profile-infos i {
  margin-top: 3px;
}

.ant-collapse.roles-collapse {
  background-color: transparent;
}
.ant-collapse.roles-collapse > .ant-collapse-item {
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}
.ant-collapse.roles-collapse > .ant-collapse-item > .ant-collapse-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  min-height: 66px;
}
.ant-collapse.roles-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: rgba(0, 0, 0, 0.65);
}
.ant-collapse.roles-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-collapse.roles-collapse.ant-collapse-borderless > .ant-collapse-item {
  border: 0;
  margin-bottom: 24px;
}
.ant-collapse.roles-collapse.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.ant-collapse.roles-collapse .ant-table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-collapse.roles-collapse .ant-table-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-top-width: 2px;
}
#role-create {
  -webkit-flex: 1 1;
          flex: 1 1;
}
#role-create .ant-card-body {
  padding: 16px 0 0 0;
}
#role-create .ant-card-body .ant-table {
  box-shadow: none;
  border-left: none;
  border-right: none;
}
#role-create-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 16px 16px 16px;
}
#role-create-header > input {
  -webkit-flex: 0 1 300px;
          flex: 0 1 300px;
}
#role-create-organization {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#role-create-organization > span {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  text-transform: lowercase;
  margin: 0 16px;
}
#role-create-organization .ant-select {
  min-width: 200px;
  max-width: 100%;
}
.role-organization {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 16px;
  color: #bfbfbf;
}

.rv-xy-plot {
  color: rgba(0, 0, 0, 0.45);
}
.rv-xy-plot__axis__line {
  stroke-width: 2px;
  stroke: #d9d9d9;
  fill: none;
}
.rv-xy-plot__axis__tick__line {
  stroke: none;
}
.rv-xy-plot__axis__tick__text {
  fill: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-weight: 700;
}
.rv-xy-plot__axis__title text {
  font-size: 12px;
  fill: rgba(0, 0, 0, 0.45);
}
.rv-xy-plot__grid-lines__line {
  stroke: #eee;
}
.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
}
/**
 * Crosshair
 */
.rv-crosshair__line {
  background: #ccc;
}
.rv-crosshair__inner {
  top: 8px;
}
.rv-crosshair__inner--left {
  right: 8px;
}
.rv-crosshair__inner--right {
  left: 8px;
}
.chart {
  position: relative;
}
.chart-tooltip .ant-card-head {
  background-color: #fafafa;
  text-align: center;
}
.chart-tooltip .ant-card-head-title {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  padding: 16px 0;
}
.chart-no-data-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
  .ant-col + .ant-col[class*="xs-24"] {
    margin-top: 32px;
  }
}
@media (max-width: 767px) {
  .ant-col + .ant-col[class*="sm-24"] {
    margin-top: 32px;
  }
}
@media (max-width: 991px) {
  .ant-col + .ant-col[class*="md-24"] {
    margin-top: 32px;
  }
}
@media (max-width: 1199px) {
  .ant-col + .ant-col[class*="lg-24"] {
    margin-top: 32px;
  }
}
@media (max-width: 1599px) {
  .ant-col + .ant-col[class*="xl-24"] {
    margin-top: 32px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
.ant-table-tbody {
  background-color: #fff;
}
.ant-table-tbody > tr > td:first-child .list-cell {
  padding-left: 24px;
}
.ant-table-tbody > tr > td:last-child .list-cell {
  padding-right: 24px;
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}
.ant-table.ant-table-without-column-header .ant-table-placeholder {
  border-radius: 4px;
}
.ant-table .ant-table-placeholder {
  border: none;
}
.ant-table .ant-table-scroll-position-left td,
.ant-table .ant-table-scroll-position-left th {
  white-space: nowrap;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  background: #f9f9fb;
}
.table-row-even {
  background-color: #f9f9fb;
}
.ant-table-title {
  padding: 16px 24px;
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  top: 0;
}
.ant-table-title .ant-input-search {
  margin: 0!important;
}
.ant-table-row-expand-icon {
  line-height: 17px;
}
.table-row-clickable {
  cursor: pointer;
}
tr.ant-table-expanded-row .ant-table {
  box-shadow: none;
  border: none;
  border-radius: 0;
}
tr.ant-table-expanded-row .ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  box-shadow: inset 0 5px 8px -6px rgba(0, 0, 0, 0.05);
}
tr.ant-table-expanded-row .ant-table-thead th {
  border-radius: 0!important;
}
tr.ant-table-expanded-row .ant-table-thead > tr > th,
tr.ant-table-expanded-row .ant-table-tbody > tr > td {
  padding: 12px 16px;
  background: rgba(245, 240, 255, 0.5);
}
tr.ant-table-expanded-row .ant-table-tbody > tr > td {
  background: rgba(245, 240, 255, 0.5);
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0 0 0 -50px;
}
.ant-card {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}
.ant-card-extra .ant-tag {
  margin: 0;
}
.ant-card-head {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.6px;
}
.ant-card-body .card-footer {
  margin: 0 -24px -24px -24px;
  background-color: #fafafa;
  min-height: 48px;
  border-top: 1px solid #e8e8e8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 16px;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 0 0 24px;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 0 0 24px;
}
.ant-btn {
  text-transform: uppercase;
  line-height: 40px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.ant-btn.ant-btn-lg {
  font-size: 18px;
}
.ant-btn.ant-btn-sm {
  line-height: 32px;
}
.ant-tag {
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
  margin: 0;
  cursor: default;
}
.ant-tag + .ant-tag {
  margin-left: 8px;
}
.ant-tag:hover {
  opacity: 1;
}
.ant-pagination {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-weight: 700;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 0px;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px;
  border: none;
}
.ant-pagination-prev a.ant-pagination-item-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #d9d9d9;
}
.ant-pagination-next a.ant-pagination-item-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #d9d9d9;
}
.ant-pagination-item {
  border: none;
  min-width: 28px;
  height: 24px;
  line-height: 24px;
}
.ant-pagination-item a {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 14px;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  background-color: #f5f0ff;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item.ant-pagination-item-active {
  background-color: #3022b3;
  border-color: #3022b3;
}
.ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination-item.ant-pagination-item-active:focus,
.ant-pagination-item.ant-pagination-item-active:hover {
  background-color: #3022b3;
}
.ant-pagination-item.ant-pagination-item-active:focus a,
.ant-pagination-item.ant-pagination-item-active:hover a {
  color: #fff;
}
.ant-pagination-prev:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
.ant-pagination-next:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
.ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  border-color: #d9d9d9;
  background-color: #f5f0ff;
}
.ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link svg,
.ant-pagination-next.ant-pagination-disabled .ant-pagination-item-link svg {
  fill: rgba(0, 0, 0, 0.25);
}
.ant-collapse {
  border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: static;
  line-height: 14px;
  margin: 0 8px 0 0;
  color: #f3edff;
  -webkit-transform: none;
          transform: none;
}
.ant-collapse-content {
  border: none;
}
.ant-dropdown-menu-item-selected {
  background-color: #f9f9fb;
}
.ant-drawer-content-wrapper {
  max-width: 100%;
}
.ant-drawer-wrapper-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #f9f9fb;
}
.ant-drawer-close {
  height: 80px;
}
.ant-drawer-header {
  padding: 29px 24px 28px;
}
.ant-drawer-title {
  font-size: 18px;
  color: #100d66;
}
.ant-drawer-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 24px 32px 97px;
}
.ant-form-item-label label {
  margin-right: 8px;
}
.ant-form-item-label label::after {
  content: none;
}
h2.ant-typography,
.ant-typography h2 {
  color: #100d66;
  font-size: 16px;
  margin-bottom: 32px;
}
.ant-calendar-picker-input.ant-input-sm {
  padding: 0 7px;
  font-size: 14px;
  line-height: 32px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  font-size: 14px;
}
.ant-calendar-range .ant-calendar-input-wrap {
  height: auto;
}
.ant-calendar-range-middle {
  line-height: 41px;
}
.ant-card-cover > .img-fill {
  width: 321px;
  height: 150px;
}

